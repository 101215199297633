import Vue from "vue";
import Plugin from "../../plugin";
import Widget from './widget';

class WidgetsPlugin extends Plugin {

  static install() {
    Vue.prototype.$widgets = new WidgetsPlugin();
  }

  constructor() {
    super();
    this.items = [];
  }

  register(component, configComponent = null, fields, defaultFilters = []) {
    if (this.items.every((item) => item.widget_type !== component.name)) {
      this.items.push(new Widget(component, configComponent, fields, defaultFilters));
    }
  }

  find(widget_type) {
    return this.items.find((i) => i.widget_type === widget_type);
  }
}

export default WidgetsPlugin;
